const zIndexes = {
  debugBar: 2e4,
  loadingOverlay: 1e4,
  toast: 900,
  modal: 800,
  lightGallery: 700,
  menuOverlay: 600,
  topPopups: 550,
  menu: 500,
  floatingButtons: 400
};
export {
  zIndexes
};
